export interface UEditorOptions {
  [key: string]: any;
  /** å½ä½ ä½¿ç¨ `cdn` æ¶ï¼å±æ§å¿å¡«ï¼ç¸å½äºæ´ä¸ª Ueditor æéè¦è¯­è¨ãä¸»é¢ãå¯¹è¯æ¡ç­æ ¹è·¯å¾ */
  UEDITOR_HOME_URL: string;
  /** æå¡å¨ç»ä¸è¯·æ±æ¥å£è·¯å¾ */
  serverUrl?: string;
  /** å·¥å·æ ä¸çææçåè½æé®åä¸ææ¡ï¼å¯ä»¥å¨newç¼è¾å¨çå®ä¾æ¶éæ©èªå·±éè¦çä»æ°å®ä¹ */
  toolbars?: string[][];
  /** ç¼è¾å¨å±çº§çåºæ°,é»è®¤ `900` */
  zIndex?: number;
}

export class UEditorConfig {
  /**
   * Ueditor [åç«¯éç½®é¡¹](http://fex.baidu.com/ueditor/#start-config)
   */
  options: UEditorOptions;

  /**
   * æå®ueditor.jsåconfig.jsè·¯å¾ï¼è¿æ¯å¯å¨ Ueditor å¿å¤
   * - **å¡å¿**æå® `options.UEDITOR_HOME_URL`ï¼è½ç¶é»è®¤ä¼æ ¹æ® `config.js` è·¯å¾è·åï¼ä½æäºç¯å¢ä¸æ´æä¿è¯ï¼
   */
  js: string[];

  /**
   * Hook
   * - å¨ Ueditor å¯¹è±¡å è½½å®æåæ§è¡
   * - åªæ§è¡ä¸æ¬¡
   */
  hook?: (ue: any) => void;
}
